import React, { useCallback, useMemo, useRef } from "react";
import { BotLanguage, WebChatHeaderOptions, WebChatState } from "../WebChatContext";
import { DruidCookies, WebChatOptions } from "../WebChatOptions";
import useSagaReducer from 'use-saga-reducer';
import { put, takeLatest, all, take, call, race , takeEvery, delay} from 'redux-saga/effects';
import logger from 'use-reducer-logger';
// import {  ServiceStatus } from '@druidsoft/botframework-directlinejs'; // not sure for now if resolutions in package.json will work
import { mergeChatOptions } from '../utils/mergeChatOptions';
import { removeAllCookies, setCookie } from "../utils/cookiesUtils";
import { DirectLine, ServiceStatus } from 'botframework-directlinejs';
import { AppConsts, druidLanguageComparison, mapConversationHistoryActivityToActivity, shouldEnter_fileUploadInputMode, shouldEnter_sensitiveDataInputMode } from "../logics/druidBusinessLogic";
import createEmotion from "@emotion/css/create-instance";
import { expandStringToLength, randomHash } from "../utils/randomHash";
import { babelTransformObject } from "../utils/babelTransform";
import { mobileAndTabletcheck } from "../utils/mobileAndTabletCheck";
import stopDictate from 'botframework-webchat-core/lib/actions/stopDictate';
import setSendBox from 'botframework-webchat-core/lib/actions/setSendBox';
import submitSendBox, { SUBMIT_SEND_BOX } from 'botframework-webchat-core/lib/actions/submitSendBox';

import setSuggestedActions from 'botframework-webchat-core/lib/actions/setSuggestedActions';

import { POST_ACTIVITY_FULFILLED } from 'botframework-webchat-core/lib/actions/postActivity';


import setNotification from 'botframework-webchat-core/lib/actions/setNotification';
import dismissNotification from 'botframework-webchat-core/lib/actions/dismissNotification';
import disconnect from 'botframework-webchat-core/lib/actions/disconnect';
import activityFromBot from 'botframework-webchat-core/src/definitions/activityFromBot';


import setDisabled from '../actions/druid/setDisabled';

import updateIn from 'simple-update-in';
import AES from "crypto-js/aes";
import CryptoJsUTF8 from "crypto-js/enc-utf8";
import { encryptSensitiveData, searchAssist } from "../WebChatServices";
import deepmerge from "deepmerge";
import { buildContextMenuWithTranslations } from "../logics/buildContextMenuWithTranslations";


// import { webChatEventHandler } from './../utils/druidWebChatMiddleware';

export type SET_MERGEDCHATOPTIONS_SIMPLE_VARIABLE_TYPE = { type: 'SET_MERGEDCHATOPTIONS_SIMPLE_VARIABLE', payload: { variablePath: string[], variableValue: any } };
export type DruidAction =
  | { type: 'SET_INITIALIZED', payload: boolean }
  | { type: 'SET_LOADED', payload: boolean }
  | { type: 'SET_LOADING', payload?: boolean }
  | { type: 'SET_MINIMIZED', payload: boolean }
  | { type: 'SET_NEWMESSAGE', payload: boolean }
  | { type: 'SET_SIDE', payload: 'left' | 'right' }
  | { type: 'SET_DIRECTLINEOPTIONS', payload: WebChatState["directlineOptions"] }
  | { type: 'SET_SPEECHOPTIONS', payload: Partial<{ speechToken: string, speechRegion: string, isVoiceChannel: boolean }> }
  | { type: 'SET_DIRECTLINEINSTANCE', payload: DirectLine }
  | { type: 'SET_LOADCONFIGURATIONRESULT', payload: any }
  | { type: 'SET_MERGEDCHATOPTIONS', payload: Partial<WebChatOptions> }
  | SET_MERGEDCHATOPTIONS_SIMPLE_VARIABLE_TYPE
  | { type: 'SET_MIDDLEWARE_OR_EVENT_HANDLER', payload: { handlerName: string, handler: any, withDecorate: boolean} }
  | { type: 'SET_PRISTINE_MERGEDCHATOPTIONS', payload: Partial<WebChatOptions> } // chat options after all merges. does not contain runtime updates, like mergedchatoptions
  | { type: 'SET_BF_WEBCHAT_STORE', payload: WebChatState["store"] }
  | { type: 'SET_HEADEROPTIONS', payload: Partial<WebChatHeaderOptions> }
  | { type: 'SET_BOTLANGUAGES', payload: BotLanguage[] }
  | { type: 'SET_SELECTEDLANGUAGE', payload: string, skipPostEvent?: boolean }
  | { type: 'HIDE_CONTAINERELEMENT', payload: boolean }
  | { type: 'HIDE_SUGGESTEDACTIONS', payload: boolean }
  | { type: 'SET_SENSITIVEDATAINPUT_MODE', payload: boolean }
  | { type: 'SET_FILEUPLOADINPUT_MODE', payload: boolean} // not used
  | { type: 'SET_SHOWTYPING', payload: boolean | string }
  | { type: 'SET_DISABLE_CARD_ACTIONS', payload: boolean}
  | { type: 'SET_IS_WINDOW_FOCUSED', payload: boolean }
  | { type: 'SET_IS_MOBILE', payload: boolean }
  | { type: 'SET_IS_VOICEINPUT', payload: boolean }
  | { type: 'SET_CUSTOM_BUTTON_TOGGLE', payload: boolean } // no reducer. used for external handling
  | { type: 'RESET', payload?: Partial<WebChatOptions>, withAutoExpand?: boolean, withNewDruidConversationToken?: string, withNewAuthorizationDtoDetails?: any, includingCookies?: boolean }
  | { type: 'CLEAR_COOKIES', payload?: any }
  | { type: 'OPEN_WEBCHAT', payload?: any }
  | { type: 'CLOSE_WEBCHAT', payload?: any }
  | { type: 'SERVICESTATUS_CHANGE', payload: ServiceStatus }
  | { type: 'SET_CONVERSATION_TOKEN', payload: string }
  | { type: 'SET_AUTHORIZATION_RESULT_DTO', payload: any}
  | { type: 'SET_CONTEXTMENU', payload: string[] }
  | { type: 'SET_CONTEXTMENU_TRANSLATIONS', payload: { [key: string]: string[] }}
  | { type: 'SET_DRUID_COOKIES', payload: any } // activity
  | { type: 'SET_LOCALE', payload: string }
  | { type: 'SET_INPUT_BLOCKED', payload: boolean }
  | { type: 'SET_CONVERSATIONHISTORY_ACTIVITIES', payload: any[]}
  | { type: 'CONVERSATION_HISTORY_SHOWALL', payload: any }

  | { type: 'INITIALIZATION_STATUS', payload: 'uninitialized' | 'success' | 'failed', withMessage?: string } // activity

  | { type: 'INTERNAL/DISABLE_UNTIL_ANY_CONFIRMED_ACTIVITY', payload?: any }
  | { type: 'INTERNAL/DISABLE_CARDACTIONS_UNTIL_ANY_CONFIRMED_ACTIVITY', payload?: any }
  | { type: 'INTERNAL/PROCCESS_SENSITIVE_MESSAGE', payload: { originalSendMessage: any } }
  | { type: 'INTERNAL/AJAX_REQUEST_FINISHED', payload: { name: string, encryptedPassword?: string } }
  ;

export type DruidContext = [druidWebChatState: WebChatState, druidWebChatDispatchWithMiddleware: (action: DruidAction) => void, getState: () => WebChatState, druidStyleToEmotionObject: (style: any) => any];

export function useWebChatReducer(initialOptions: WebChatOptions): DruidContext {
  const initialState: WebChatState = useMemo(() => ({
    instanceID: initialOptions.instanceID,
    initialized: false,
    loaded: false,
    minimized: initialOptions.isWidget!,
    newMessage: false,
    containerElementVisible: true,
    side: 'right',
    locale: window?.navigator?.language || 'en-US',
    contextMenuItems: [],
    contextMenuTranslations: {},
    directlineOptions: null,
    directlineInstance: initialOptions.botConnection,
    speechOptions: {},
    isMobile: mobileAndTabletcheck(),
    isVoiceInput: false,
    inputBlocked: initialOptions?.UI?.blockingInputDruidSendBox || false,
    sensitiveDataInputMode: false,
    fileUploadInputMode: false, // not used
    mergedChatOptions: initialOptions,
    pristineMergedChatOptions: initialOptions, // chat options after all merges. does not contain runtime updates, like mergedchatoptions
    conversationHistoryShowAll: false,
    headerOptions: {
      showHeader: true,
      showChatTitle: true,
      showCloseButton: true,
      showChangeSideButton: true,
      showLanguageSelector: true,
      showIcon: true,
      showAvatar: false,
      showVoiceInputSelector: true,
      showReload: process.env.NODE_ENV === "development",
      showReset: true
    },
    showTyping: false,
    cardActionsDisabled: false,
    botLanguages: [],
    // isWindowFocused: true,
    store: null,
    bfWebchatProps: null,
    serviceStatus: ServiceStatus.Offline,
    conversationToken: null,
    INITIALIZATION_STATUS: 'uninitialized',
    INITIALIZATION_STATUS_MESSAGE: null
  }), [initialOptions]);

  const initializeState = useCallback((newOptionsFromReset?: Partial<WebChatState>) => {
    // MM: reInitializeChat with init call is not equal to type: 'RESET' action. reset keeps previous mergedChatOptions form state.
    if (newOptionsFromReset) {
      initialState.mergedChatOptions = mergeChatOptions(initialState.mergedChatOptions, newOptionsFromReset);
    }
    return initialState;
  }, [initialState]);

  const lastState = useRef(initialState);
  const getState = useCallback(() => lastState.current, [])

  const reduce = (state: WebChatState, action: DruidAction): WebChatState => {
    switch (action.type) {
      case 'SET_INITIALIZED':
        return { ...state, initialized: action.payload };
      case 'SET_LOADING': {
        return state;
      }
      case 'SET_LOADED':
        return { ...state, loaded: action.payload };
      case 'SET_MINIMIZED':
        return { ...state, minimized: action.payload };
      case 'SET_NEWMESSAGE':
        return { ...state, newMessage: action.payload };
      case 'SET_SIDE':
        return { ...state, side: action.payload };
      case 'SET_DIRECTLINEOPTIONS':
        return { ...state, directlineOptions: { ...state.directlineOptions, ...action.payload } };
      case 'SET_SPEECHOPTIONS':
        return { ...state, speechOptions: { ...state.speechOptions, ...action.payload } };
      case 'SET_DIRECTLINEINSTANCE':
        return { ...state, directlineInstance: action.payload };
      case 'SET_IS_MOBILE':
        return { ...state, isMobile: action.payload };
      case 'SET_IS_VOICEINPUT': {
        return { ...state, isVoiceInput: action.payload };
      }
      case 'SET_MERGEDCHATOPTIONS':
        {
          const ret = mergeChatOptions(state.mergedChatOptions, action.payload)
          return { ...state, mergedChatOptions: ret };
        }
      case 'SET_PRISTINE_MERGEDCHATOPTIONS':
        {
          return { ...state, pristineMergedChatOptions: action.payload };
        }
      case 'SET_MERGEDCHATOPTIONS_SIMPLE_VARIABLE':
        {
          const nextState = updateIn(state, ["mergedChatOptions", ...action.payload.variablePath], (variablePath) => action.payload.variableValue);
          return nextState;
        }
      case 'SET_SHOWTYPING': {
        if(state.showTyping === action.payload) {
          return state;
        }
        return { ...state, showTyping: action.payload };
      }
      case 'SET_DISABLE_CARD_ACTIONS': {
        return { ...state, cardActionsDisabled: action.payload };
      }
      case 'SET_BF_WEBCHAT_STORE':
        return { ...state, store: action.payload };
      case 'HIDE_CONTAINERELEMENT':
        return { ...state, containerElementVisible: !action.payload };
      case 'HIDE_SUGGESTEDACTIONS': {
        // const ret = mergeChatOptions(state.mergedChatOptions, { UI: { hideSuggestedActions: action.payload}});
        // return { ...state,  mergedChatOptions: ret};
        const nextState = updateIn(state, ['mergedChatOptions', 'UI', 'hideSuggestedActions'], (hideSuggestedActions) => action.payload);

        return nextState;
      }
      case 'SET_SENSITIVEDATAINPUT_MODE': {
        let nextState = updateIn(state, ['mergedChatOptions', 'styleOptions', 'sendBoxTextWrap'], () => !action.payload);
        nextState = updateIn(nextState, ['sensitiveDataInputMode'], () => action.payload);

        return nextState;
      }
      case 'SET_FILEUPLOADINPUT_MODE' : { // not used
        const nextState = updateIn(state, ['fileUploadInputMode'], () => action.payload);

        return nextState;
      }

      case 'SET_INPUT_BLOCKED': {
        const nextState = updateIn(state, ['inputBlocked'], () => action.payload);

        return nextState;
      }

      case 'CONVERSATION_HISTORY_SHOWALL': {
        return { ...state, conversationHistoryShowAll: true}
      }
      case 'SERVICESTATUS_CHANGE':
        return { ...state, serviceStatus: action.payload }
      case 'SET_CONVERSATION_TOKEN': {
        let nextState = state;
        if(state.mergedChatOptions?.authorizationResultDto) {
          nextState = updateIn(nextState, ["mergedChatOptions", "authorizationResultDto", "conversationToken"], () => action.payload);
        }

        nextState = updateIn(nextState, ["conversationToken"], () => action.payload);

        return nextState;

      }

      case 'SET_AUTHORIZATION_RESULT_DTO': { // used only when authorizationResultDto is present
        let nextState = state;
       
        if(state.mergedChatOptions?.authorizationResultDto) {
          const mergedAuthorizationResultDto = {...state.mergedChatOptions.authorizationResultDto, ...action.payload };
          nextState = updateIn(state, ["mergedChatOptions", "authorizationResultDto"], () => mergedAuthorizationResultDto);
        }
        
        return nextState;
      }
      case 'SET_CONTEXTMENU':
        return {
          ...state,
          contextMenuItems: action.payload
        }
      case 'SET_CONTEXTMENU_TRANSLATIONS':
        return {
          ...state,
          contextMenuTranslations: action.payload
        }
      case 'SET_HEADEROPTIONS':
        return {
          ...state,
          headerOptions: { ...state.headerOptions, ...action.payload }
        }
      case 'SET_BOTLANGUAGES':{
        const toSetLanguages = action.payload;
        if(!toSetLanguages.find(lg => lg.id === - 1)){
          toSetLanguages.push({ 
            name: 'DEF',
            displayName: 'Default',
            nativeName: 'Default', // could be renamed
            isDefault: false,
            isSelected: false,
            icon: 'fa fa-globe',
            id: -1
          });
        }

        return {
          ...state,
          botLanguages: toSetLanguages
        };
      }
        
      case 'SET_LOCALE':
        return {
          ...state,
          locale: action.payload
        }

      /* fetch handlers result*/
      case 'INITIALIZATION_STATUS': {
        return { ...state, INITIALIZATION_STATUS: action.payload, INITIALIZATION_STATUS_MESSAGE: action.withMessage || null }
      }
      case 'RESET':
        {
          //    initialState.instanceID = ;
          const { conversationToken_namePrefix, druidWebChatIsOpened_namePrefix } = state.mergedChatOptions || {};
          action.payload = { ...(action.payload || {}), conversationToken_namePrefix, druidWebChatIsOpened_namePrefix };
          return initializeState(action.payload);
        }
      default:
        return state
    }
  }

  const memoizedReducer = useCallback((state: WebChatState, action: DruidAction): WebChatState => {
    const ret = reduce(state, action);
    lastState.current = ret;
    return ret;

  }, [initializeState]); // <--- if you have vars/deps inside the reducer that changes, they need to go here

  const emotionPool = {};
  const druidStyleToEmotionObject = useMemo(() => {
    // Emotion doesn't hash with nonce. We need to provide the pooling mechanism.
    // 1. If 2 instances use different nonce, they should result in different hash;
    // 2. If 2 instances are being mounted, pooling will make sure we render only 1 set of <style> tags, instead of 2.
    const emotion =
      emotionPool[initialState.instanceID] || (emotionPool[initialState.instanceID] = createEmotion({ key: `druid-webchat--css-${randomHash()}`}));

    return style => emotion.css(style);
  }, [initialState.instanceID]);
  function* handleOpenWebchat(action) {
    const currentState = getState();

    setCookie(DruidCookies.DruidWebChatIsOpened, true, { domain: currentState.mergedChatOptions.cookieDomain || "" }, currentState);

    if(currentState.directlineInstance) {
      currentState.directlineInstance.sendMessagesRead = true;
    }
    
    yield all([
      put({
        type: 'SET_MINIMIZED',
        payload: false
      }),
      put({
        type: 'SET_NEWMESSAGE',
        payload: false
      }),
      put({
        type: 'HIDE_CONTAINERELEMENT',
        payload: true
      })
    ]);
  };

  function* handleCloseWebchat(action) {
    const currentState = getState();

    setCookie(DruidCookies.DruidWebChatIsOpened, false, { domain: currentState.mergedChatOptions.cookieDomain || "" }, currentState);

    if(currentState.directlineInstance) {
      currentState.directlineInstance.sendMessagesRead = false;
    }

    if (currentState.mergedChatOptions.backgroundTrafficEnabled) {
      yield all([
        put({
          type: 'SET_MINIMIZED',
          payload: true
        }),
        put({
          type: 'SET_NEWMESSAGE',
          payload: false
        }),
        // put({
        //   type: 'SET_INITIALIZED',
        //   payload: false
        //   }),
        // put({
        //   type: 'INITIALIZATION_STATUS',
        //   payload: 'uninitialized'
        //   }),
        put({
          type: 'HIDE_CONTAINERELEMENT',
          payload: false
        })
      ]);
    } else { // if backgroundTrafficEnabled == false, RESET chat. difference from bfv3 -> it used to destroy only webchat connection.
      // we should refactor in bfv4 also (don't use RESET on close)
      // Should we refactor ? RESET is safer. directlineOptions.token needs to be refreshed when collapsed if RESET will not be used
      yield all([
        put({
          type: 'RESET'
        })
      ]);
    }



    // yield put({
    //     type: 'SET_MINIMIZED',
    //     payload: true
    //   });
    // yield put({
    //     type: 'SET_NEWMESSAGE',
    //     payload: false
    //   });
  };

  function* handleChangeStatus(action: any) {
    const { store } = getState();
    if (action.type === 'SERVICESTATUS_CHANGE') {
      if (action.payload === ServiceStatus.Offline) {

        yield put(setDisabled(true));

        yield store?.dispatch(setNotification({
          id: 'servicestatus_offline_notification',
          level: 'error',
          message: 'Reconnecting - Please check your Internet connection',
          alt: '',
          data: {}
        }));
      }

      if (action.payload === ServiceStatus.Warning) {
        yield store?.dispatch(setNotification({
          id: 'servicestatus_warning_notification',
          level: 'warn',
          message: 'Weak Connection',
          alt: '',
          data: {}
        }));
      }

      if (action.payload === ServiceStatus.Online) {

        yield all([
          put(setDisabled(false)),
          store?.dispatch(dismissNotification('servicestatus_warning_notification')),
          store?.dispatch(dismissNotification('servicestatus_offline_notification'))
        ]);

      }
    }
    // druidWebChatDispatch({
    //   type: ''
    // })
    // 'Weak Connection' : 'Offline - Please check your Internet Connection'
  }

  // function* handleLoaded(action) {
  //   yield all([
  //     put({
  //       type: 'SET_MERGEDCHATOPTIONS',
  //       payload: action
  //     }),
  //     put({
  //       type: 'SET_LOADED',
  //       payload: true
  //     })
  //   ]);

  // }
  function* handleChangeDruidCookies(action) {
    const currentState = getState();
    const { mergedChatOptions, minimized } = currentState;
    const activity = action.payload;
    if (!!mergedChatOptions.druidCookieConsentEnabled) {
      setCookie(DruidCookies.DruidCookieConsent, true, { domain: mergedChatOptions.cookieDomain || "" }, currentState);
    }

    setCookie(DruidCookies.DruidWebChatIsOpened, !minimized, {  domain: mergedChatOptions.cookieDomain || "" }, currentState); //getWebchatCollapsedState()

    var parsedJSON = JSON.parse(activity.value);

    setCookie(DruidCookies.DruidConversationToken, parsedJSON.DruidConversationToken || currentState.conversationToken, { domain: mergedChatOptions.cookieDomain || "" }, currentState);

    return;
  }

  function* handleMergedChatOptions(action) {
    // !!! IS NOT SAFE TO DESTRUCTURATE COMPLEX OBJECT HERE WITHOUT SAFEGUARDS !!! ///
    const { locale } = action.payload;
    // const { mergedChatOptions: {locale: prevLocale = null} } = {} = getState();

    if (locale) {
      yield all([
        put({
          type: 'SET_LOCALE',
          payload: locale
        })
      ]);
    }
  }

  function* handleSimpleVariableUpdate(action: SET_MERGEDCHATOPTIONS_SIMPLE_VARIABLE_TYPE) {
    if(action.payload.variablePath) {
      if(action.payload.variablePath.find(key => key === "userAvatarImage")) {
        const userAvatarImageValue = action.payload.variableValue;
        if(userAvatarImageValue || userAvatarImageValue === '') { // if avatarImage update -> userAvatarInitials should be '' when has image, should be undefined when not
          yield all([
            put({
              type: 'SET_MERGEDCHATOPTIONS_SIMPLE_VARIABLE',
              payload: { variablePath: ['styleOptions', 'userAvatarInitials'], variableValue: userAvatarImageValue ? '' : undefined }
            })
          ]);
         
        }
      }
    }
  }

  function* handleSetSelectedLanguage(action) {

    const name = action.payload;
    const skipPostEvent = !!action.skipPostEvent;
    if (!name) return;
    const { botLanguages, mergedChatOptions, directlineInstance } = getState();
    const selectedLanguage = botLanguages.find((bl) => bl.name === name) || botLanguages.find(bl => druidLanguageComparison(bl.name, name)) || botLanguages.find(bl => bl.id === AppConsts.DefaultLanguageId);
    const currentState = getState();
    const { contextMenuItems, contextMenuTranslations = {} } = currentState;
    // if (selectedLanguage) {
    const toSetLanguageName = name || selectedLanguage?.name;

    setCookie(DruidCookies.DruidConversationLanguage, toSetLanguageName, { domain: mergedChatOptions.cookieDomain || "" }, currentState);
    !skipPostEvent && directlineInstance?.postActivity({
      from: { id: mergedChatOptions.userId || "" },
      name: 'LanguageChanged',
      type: 'event',
      value: toSetLanguageName
    }).toPromise();

    const selectedContextMenuTranslation = buildContextMenuWithTranslations(contextMenuItems, contextMenuTranslations, botLanguages.find(bl => bl.isDefault)?.name, selectedLanguage?.name);
    
    yield all([
      selectedLanguage && put({
        type: 'SET_BOTLANGUAGES',
        payload: botLanguages.map(bl => ({
          ...bl,
          isSelected: selectedLanguage.id == bl.id //druidLanguageComparison(bl.name, selectedLanguage.name)
        }))
      }),
      selectedLanguage && selectedContextMenuTranslation && put({
        type: 'SET_CONTEXTMENU',
        payload: selectedContextMenuTranslation
      }),
      put({
        type: 'SET_LOCALE',
        payload: toSetLanguageName
      })
    ]);
    // }

  }

  function* handleSetContextMenuTranslations(action) {
    const currentState = getState();
    const { contextMenuItems,  botLanguages} = currentState;
    const contextMenuTranslations = action.payload || {};
    const selectedLanguage = botLanguages.find((bl) => bl.isSelected);

    const selectedContextMenuTranslation = buildContextMenuWithTranslations(contextMenuItems, contextMenuTranslations, botLanguages.find(bl => bl.isDefault)?.name, selectedLanguage?.name);

    yield selectedLanguage && selectedContextMenuTranslation && put({
      type: 'SET_CONTEXTMENU',
      payload: selectedContextMenuTranslation
    });
    
  }
  function* handleSetIsVoiceInput(action) {
    const currentState = getState();
    if (action.payload === false) { // stop dictate if change from voice to keyboard and already in listening state
      currentState.store?.dispatch(stopDictate());
      currentState.store?.dispatch(setSendBox(''));
    }
  }

  function* handleResetWithOptions(action) {
    if(action.includingCookies) {
      yield put(({ type: 'CLEAR_COOKIES' }));
    }

    yield take('SET_LOADED');

    if (!!action.withNewAuthorizationDtoDetails) {
      yield put(({ type: 'SET_AUTHORIZATION_RESULT_DTO', payload: action.withNewAuthorizationDtoDetails }));
    }

    if (action.withNewDruidConversationToken) {
      yield put(({ type: 'SET_CONVERSATION_TOKEN', payload: action.withNewDruidConversationToken }));
    }

    if (action.withAutoExpand) {
      yield put(({ type: 'OPEN_WEBCHAT' }));
    }

    
  }

  function* handleSensitiveDataInputMode(action) {
    const currentState = getState();
    if (action.payload === true) {
      yield put({ type: 'SET_IS_VOICEINPUT', payload: false });
    } else {
      yield currentState.store?.dispatch(dismissNotification('encrypt_message_request_error_notification'));
    }
  }

  function* handleDisableAndConfirmActivity(action) {
    yield put(setDisabled(true));

    yield take('DIRECT_LINE/POST_ACTIVITY_FULFILLED');

    yield put(setDisabled(false));

    const currentState = getState();
    currentState.store?.dispatch(setSendBox(''));

    yield put({
      type: 'SET_SENSITIVEDATAINPUT_MODE',
      payload: false
    });
  }

  function* handleDisableCardActionUntilConfirmActivity(action) {
      yield put({type: 'SET_DISABLE_CARD_ACTIONS', payload: true});

      yield take(incomingActivityAction => incomingActivityAction.type === 'DIRECT_LINE/INCOMING_ACTIVITY' && (!action.payload.fromBot || activityFromBot(incomingActivityAction.payload.activity)));
  
      yield put({type: 'SET_DISABLE_CARD_ACTIONS', payload: false});

      // if this is removed, when clicking on a hero button, the chat scrolls to the card's previos clicked button.
      // DruidWebchat_v2.hooks.scrollToEnd();
      DruidWebchat_v2.hooks.scrollTo({ scrollTop: '100%'}, 'auto');
  }
  function* handleSearchAssist(action) {
    const { text } = action.payload || {};
    const currentState = getState();
    if(text && currentState.mergedChatOptions.searchAssistEnabled) {
      const { cancelResult } = yield  race({ delayResult: delay(1000), cancelResult: take(SUBMIT_SEND_BOX)});
      if(cancelResult) {
        return;
      }
      const response = yield call(() => (searchAssist({
        apcUrl: currentState.mergedChatOptions.apcUrl!,
        abpToken: (window as any).abpToken,
        searchText: text,
        botId: currentState.mergedChatOptions.botId!,
        withCredentials: currentState.mergedChatOptions.withCredentials ? "include" : "omit",
        languageCode: currentState.store?.getState()?.language
      })
        .then((response) => {
          return response.json();
        })
        .catch(ex => {
          return "";
        }))
      );
     
  
      if (response && response.result && Array.isArray(response.result) && (response.result[0].tuplesList?.length > 0 || response.result[0].topLabel)) {
        const responseResult = response.result[0];
        const tuplesList = responseResult.tuplesList?.map(tl => tl.item2);
        const datasource: any[] = tuplesList.length ? tuplesList :  [responseResult.topLabel];
        if (!datasource.some(s => !s)) {


          currentState.store.dispatch(setSuggestedActions(datasource.map(ds => ({
            type: 'imBack',
            title: ds.split("=")[1],
            value: ds.split("=")[1],
            text: ds.split("=")[1]
            }))
          ));
          yield take(incomingActivityAction => incomingActivityAction.type === POST_ACTIVITY_FULFILLED && (!action.payload.fromBot || !activityFromBot(incomingActivityAction.payload.activity)));

          currentState.store?.dispatch(setSendBox(''));
        }
      } else {
        currentState.store.dispatch(setSuggestedActions([]));
      }
    }
}


  function* proccessSensitiveMessage(action) {

    const currentState = getState();

    const { originalSendMessage } = action.payload;

    const originalTextValue = originalSendMessage.payload.text.trim();

    if (!originalTextValue) {
      return;
    }

    const conversationId = (currentState.directlineInstance as any).conversationId;

    const enc_key = CryptoJsUTF8.parse(expandStringToLength(conversationId, 32));
    const enc_IV = CryptoJsUTF8.parse(expandStringToLength(conversationId, 16));
    const client_side_encrypted_data = AES.encrypt(originalTextValue, enc_key, { iv: enc_IV }).toString();
    // const dec =  AES.decrypt(client_side_encrypted_plain_password, (currentState.directlineInstance as any).conversationId).toString(CryptoJsUTF8);

    yield put(setDisabled(true));

    const encryptedData = yield call(() => (encryptSensitiveData({
      baseUrl: currentState.mergedChatOptions.authorizationBaseUrl || currentState.mergedChatOptions.baseUrl!,
      conversationToken: currentState.directlineInstance?.getCurrentToken()!,
      dataToEncrypt: client_side_encrypted_data,
      // botId: currentState.mergedChatOptions.botId!,
      withCredentials: currentState.mergedChatOptions.withCredentials ? "include" : "omit"
    })
      .then((response) => {
        return response.json();
      })
      .catch(ex => {
        return "";
      }))
    );

    if (encryptedData) {
      yield currentState.store?.dispatch(dismissNotification('encrypt_message_request_error_notification'));

      originalSendMessage.payload.text = '******';
      originalSendMessage.payload.channelData = originalSendMessage.payload.channelData || {};
      originalSendMessage.payload.channelData.encryptedData = encryptedData;
      originalSendMessage.payload._druidInternal_passThrough_sensitiveDataInputMode = true;
      currentState.store?.dispatch(originalSendMessage); // watch carefuly. might not pass through BFStore middleware (don't send any message, except cancel and _druidInternal_passThrough_sensitiveDataInputMode)

      const { error, success } = yield race({
        success: take('DIRECT_LINE/POST_ACTIVITY_FULFILLED'),
        error: take('DIRECT_LINE/POST_ACTIVITY_REJECTED')
      });

      if (error) {
        yield currentState.store?.dispatch(setNotification({
          id: 'encrypt_message_request_error_notification',
          level: 'error',
          message: 'Failed to proccess. Please try again.',
          alt: '',
          data: {}
        }));
      } else {
        yield put({
          type: 'SET_SENSITIVEDATAINPUT_MODE',
          payload: false
        });
      }
    } else {

      yield currentState.store?.dispatch(setNotification({
        id: 'encrypt_message_request_error_notification',
        level: 'error',
        message: 'Failed to proccess. Please try again.',
        alt: '',
        data: {}
      }));
    }
    yield put(setDisabled(false));

  }

  function* handleIncomingActivity(action) {
    const { activity } = action.payload;
    const currentState = getState();
    if (activity && !activity.channelData?.isActivityStatusUpdate) {
      if(activity.type === "message") {
        if (activityFromBot(activity)) { 
          const should_SENSITIVEDATAINPUT_MODE_be_enabled = shouldEnter_sensitiveDataInputMode(activity);
          if(currentState.sensitiveDataInputMode !== should_SENSITIVEDATAINPUT_MODE_be_enabled) {
            yield put({ type: 'SET_SENSITIVEDATAINPUT_MODE', payload:  should_SENSITIVEDATAINPUT_MODE_be_enabled });
          }

          // const should_FILEUPLOADINPUT_MODE_be_enabled = shouldEnter_fileUploadInputMode(activity);
          // if(currentState.fileUploadInputMode !== should_FILEUPLOADINPUT_MODE_be_enabled) {
          //   yield put({ type: 'SET_FILEUPLOADINPUT_MODE', payload:  should_FILEUPLOADINPUT_MODE_be_enabled });
          // }
        }
      }
    }
  }

  function* processSetMiddlewareOrEventHandler(action) {
    const transformed = yield call(() => (babelTransformObject(action.payload.handler, action.payload.withDecorate)
      .then(transformed => {
        return transformed;
      })
      .catch(() => null)
    ));
    
    // if(transformed) {
      yield put({
        type: 'SET_MERGEDCHATOPTIONS_SIMPLE_VARIABLE',
        payload: { variablePath: [action.payload.handlerName], variableValue: transformed }
      });
    // }
  }

  function* handleWebchatLoaded(action) {
    const currentState = getState();
    if(currentState?.mergedChatOptions?.onLoadHandlerPromise) {
      currentState.mergedChatOptions.onLoadHandlerPromise(action.payload);
    }
  }

  function handleClearCookies(action) {
    removeAllCookies(getState());
  }

  function* processConversationHistoryActivities(action) {
    let currentState = getState();
    let currentBFStore = currentState.store;
    if(!currentBFStore) {
      const { payload: newBFStore } = yield take('SET_BF_WEBCHAT_STORE');
      currentBFStore = newBFStore;
    }
    const conversationHistoryActivities: any[] = action.payload;
    yield put({ type: 'SET_MERGEDCHATOPTIONS_SIMPLE_VARIABLE', payload: { variablePath: ['conversationHistoryActivities'], variableValue: conversationHistoryActivities } });
    conversationHistoryActivities?.map((ch, idx) => mapConversationHistoryActivityToActivity(ch, conversationHistoryActivities?.length - 11 <= idx))
        .forEach(cha => {
          currentBFStore.dispatch({type: 'DIRECT_LINE/INCOMING_ACTIVITY', payload: { activity : cha }})
        });

  }

  function* rootSaga() {
    yield all([
      // takeLatest("OPEN_WEBCHAT", handleOpenWebchat),
      takeLatest("OPEN_WEBCHAT", handleOpenWebchat),
      takeLatest("CLOSE_WEBCHAT", handleCloseWebchat),
      takeLatest("SET_DRUID_COOKIES", (action) => handleChangeDruidCookies(action)),
      takeLatest("SERVICESTATUS_CHANGE", (action) => handleChangeStatus(action)),
      takeLatest("SET_LOADED", (action) => handleWebchatLoaded(action)),

      takeLatest("SET_MERGEDCHATOPTIONS", (action) => handleMergedChatOptions(action)),
      takeLatest<SET_MERGEDCHATOPTIONS_SIMPLE_VARIABLE_TYPE>("SET_MERGEDCHATOPTIONS_SIMPLE_VARIABLE", (action) => handleSimpleVariableUpdate(action)),
      takeLatest("SET_SELECTEDLANGUAGE", (action) => handleSetSelectedLanguage(action)),
      takeLatest("SET_IS_VOICEINPUT", (action) => handleSetIsVoiceInput(action)),
      takeLatest("SET_CONTEXTMENU_TRANSLATIONS", (action) => handleSetContextMenuTranslations(action)),
      takeLatest("RESET", (action) => handleResetWithOptions(action)),
      takeLatest("SET_SENSITIVEDATAINPUT_MODE", (action) => handleSensitiveDataInputMode(action)),
      takeLatest("INTERNAL/DISABLE_UNTIL_ANY_CONFIRMED_ACTIVITY", (action) => handleDisableAndConfirmActivity(action)),
      takeLatest("DIRECT_LINE/INCOMING_ACTIVITY", (action) => handleIncomingActivity(action)),
      takeLatest("INTERNAL/PROCCESS_SENSITIVE_MESSAGE", (action) => proccessSensitiveMessage(action)),
      takeLatest("INTERNAL/DISABLE_CARDACTIONS_UNTIL_ANY_CONFIRMED_ACTIVITY", (action) => handleDisableCardActionUntilConfirmActivity(action)),
      takeLatest("WEB_CHAT/SET_SEND_BOX", handleSearchAssist),
      takeEvery("SET_MIDDLEWARE_OR_EVENT_HANDLER", (action) => processSetMiddlewareOrEventHandler(action)),
      takeEvery("SET_CONVERSATIONHISTORY_ACTIVITIES", (action) => processConversationHistoryActivities(action)),

      takeEvery("CLEAR_COOKIES", (action) => handleClearCookies(action)),
      takeEvery("*", (action) => { return DruidWebchat_v2.customGeneratorFunction && DruidWebchat_v2.customGeneratorFunction(action) })
      // takeEvery("*", (action) => DruidWebchat_v2.customGeneratorFunction(action))

      // takeLatest("ON_LOADEDSAGA", (action) => handleLoaded(action))
    ]);

    //   yield  takeLatest("OPEN_WEBCHAT", handleOpenWebchat);

    //   yield [
    //     fork("OPEN_WEBCHAT", handleOpenWebchat), // saga1 can also yield [ fork(actionOne), fork(actionTwo) ]
    //     fork("CLOSE_WEBCHAT", handleCloseWebchat),
    // ];
  }

  // logger(reducer)
  const [druidWebChatState, druidWebChatDispatch] = useSagaReducer<any, React.Reducer<WebChatState, DruidAction>, any>(rootSaga, process.env.NODE_ENV === 'development' ? logger(memoizedReducer) : memoizedReducer, initialState, undefined, {
    onError: (error: Error, errorInfo: any) => {
      console.error(error);
      getState().store?.dispatch({
        type: 'WEB_CHAT/SAGA_ERROR'
      });

      getState().store?.dispatch(disconnect());
      druidWebChatDispatch(setDisabled(true));
    }

  });

  const druidWebChatDispatchWithMiddleware = useCallback((action: DruidAction) => { // not an actual middleware. should rename to listener.
    let ret = true;
    try {
      // webChatEventHandler && webChatEventHandler(action);
      const currentState = getState();
     
      //handler for .init({}) provided
      // initialOptions.webChatEventHandler && initialOptions.webChatEventHandler(action, currentState);

      //handler for loadConfiguration provided
      if (!action.payload?.activity?.channelData?.isActivityStatusUpdate) {
        if (currentState.mergedChatOptions.webChatEventHandler) {
          ret = currentState.mergedChatOptions.webChatEventHandler(action, currentState);
        }
      }
    } catch {

    }
    if(ret !== false) {
       druidWebChatDispatch(action);
    }
    return;
  }, [druidWebChatDispatch, initialOptions]);

  return [druidWebChatState, druidWebChatDispatchWithMiddleware, getState, druidStyleToEmotionObject];
}

